.homeSliderWrapper .swiper-button-prev::after, .homeSliderWrapper .swiper-button-next::after {
  color: white;
}
.homeSliderWrapper .swiper-pagination {
  top: 90%;
}
.homeSliderWrapper .swiper-pagination .swiper-pagination-bullet {
  color: white !important;
  background-color: white !important;
  height: 10px;
  width: 10px;
}
.homeSliderWrapper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 25px;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .slider-bgwave {
    background-size: cover !important;
    height: 650px !important;
  }
  .homeSliderWrapper {
    padding-top: 60px;
  }
  .homeSliderWrapper .slider-image {
    margin: auto;
    height: 200px;
  }
  .homeSliderWrapper .swiper-button-prev::after, .homeSliderWrapper .swiper-button-next::after {
    color: white;
  }
  .homeSliderWrapper .swiper-pagination {
    top: 90%;
  }
  .homeSliderWrapper .swiper-pagination .swiper-pagination-bullet {
    color: white !important;
    background-color: white !important;
    height: 10px;
    width: 10px;
  }
  .homeSliderWrapper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 25px;
    border-radius: 4px;
  }
}
@media (max-width: 400px) {
  .slider-bgwave {
    height: 900px !important;
  }
}
.customSliderContainer .swiper-button-prev, .customSliderContainer .swiper-button-next {
  background-color: #02559F !important;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.customSliderContainer .swiper-button-prev {
  left: 0% !important;
}
.customSliderContainer .swiper-button-next {
  left: 94% !important;
}
.customSliderContainer .swiper-button-prev::after, .customSliderContainer .swiper-button-next::after {
  color: white;
  font-size: 16px;
}
.customSliderContainer .swiper-pagination {
  top: 90%;
}
.customSliderContainer .swiper-pagination .swiper-pagination-bullet {
  color: white !important;
  background-color: white !important;
  height: 10px;
  width: 10px;
}
.customSliderContainer .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 25px;
  border-radius: 4px;
}

.mainContentBox {
  position: relative;
  overflow: hidden;
  padding-top: 80px;
}
.mainContentBox .pageBgImage {
  position: absolute;
  top: -80px;
  left: -180px;
  right: 0;
  height: 600px;
  z-index: -1;
}

@media (min-width: 1600px) {
  .mainContentBox {
    background-size: 100% 40vh !important;
  }
}
@media (min-width: 1400px) {
  .mainContentBox {
    background-size: 100% 500px !important;
  }
}
@media (max-width: 1400px) {
  .mainContentBox {
    background-size: 100% 500px !important;
  }
}
@media (max-width: 768px) {
  .mainContentBox .pageBgImage {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
}
/* homeSwiperSlider */
@media (max-width: 1920px) {
  .slider-bgwave {
    height: 500px;
  }
}
@media (max-width: 1600px) {
  .slider-bgwave {
    height: 800px;
  }
}
.slider-wrapper-main {
  background-image: url("../Assets/new/sliderbg.png");
  background-size: 100% 100%;
  height: 650px;
}

.homeSwiperSlider .content-col .title-tag {
  font-size: 35px;
  font-weight: 700;
  color: white;
}
.homeSwiperSlider .content-col .white-btn {
  background-color: white;
  color: #02559F;
  width: 140px;
  height: 42px;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}
.homeSwiperSlider .content-col .outline-btn {
  background-color: transparent;
  color: white;
  width: 140px;
  height: 42px;
  border-radius: 5px;
  border: 2px solid #FFF;
  transition: 0.3s ease-in-out;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}
.homeSwiperSlider .content-col .outline-btn:hover {
  background-color: white;
  color: #02559F;
  border-radius: 5px;
}
.homeSwiperSlider .img-col img {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.homeSwiperSlider .mySwiper {
  height: 500px;
}
.homeSwiperSlider .mySwiper .swiper-pagination.swiper-pagination-fraction.swiper-pagination-horizontal {
  color: white;
  position: absolute;
  bottom: 0px;
  left: 0;
  height: 40px;
  width: 100px;
  font-size: 25px;
}
.homeSwiperSlider .mySwiper .swiper-button-next, .homeSwiperSlider .mySwiper .swiper-button-prev {
  position: absolute;
  top: 99% !important;
  height: -moz-fit-content;
  height: fit-content;
}
.homeSwiperSlider .mySwiper .swiper-button-next::after, .homeSwiperSlider .mySwiper .swiper-button-prev::after {
  color: white;
  font-size: 20px;
}
.homeSwiperSlider .mySwiper .swiper-button-prev {
  left: 100px;
}
.homeSwiperSlider .mySwiper .swiper-button-next {
  left: 130px;
}
.homeSwiperSlider .mySwiper .homelsiderimg {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

@media (max-width: 980px) {
  .slider-wrapper-main {
    background-color: #0082C6;
    background-image: none;
    height: 650px;
  }
  .homeSwiperSlider .content-col .title-tag {
    font-size: 20px;
  }
  .homeSwiperSlider .content-col .white-btn {
    background-color: white;
    color: #02559F;
    width: 120px;
    height: 35px;
    border-radius: 5px;
    transition: 0.3s ease-in-out;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  .homeSwiperSlider .content-col .outline-btn {
    background-color: transparent;
    color: white;
    width: 120px;
    height: 35px;
    border-radius: 5px;
    border: 2px solid #FFF;
    transition: 0.3s ease-in-out;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  .homeSwiperSlider .content-col .outline-btn:hover {
    background-color: white;
    color: #02559F;
    border-radius: 5px;
  }
  .homeSwiperSlider .img-col img {
    height: 220px !important;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .homeSwiperSlider .mySwiper {
    height: 600px;
  }
}
.cardsServices {
  width: 100% !important;
}
.cardsServices .img-box {
  overflow: hidden;
  border-radius: 16px;
}
.cardsServices .img-box img {
  height: 100%;
  width: 100%;
  -o-object-fit: fill;
     object-fit: fill;
  transition: 0.3s ease-in-out;
}

.cardsServices:hover {
  width: 100% !important;
}
.cardsServices:hover .img-box img {
  height: 100%;
  width: 100%;
  -o-object-fit: fill;
     object-fit: fill;
  transform: scale(1.2);
}

.blog-detail-page .featureImg {
  border-radius: 20px;
  background: lightgray -132px -92.372px/110.811% 153.257% no-repeat;
  height: 484px;
  overflow: hidden;
  position: relative;
}
.blog-detail-page .featureImg img {
  height: 100%;
  width: 100%;
}
.blog-detail-page .featureImg .keywords {
  position: absolute;
  top: 20px;
  left: 20px;
  border-radius: 8px;
  background: #E9F7FF;
  display: inline-flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #0082C6;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.blog-detail-page .featureImg .title-box {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: end;
  padding: 20px 40px;
}
.blog-detail-page .featureImg .title-box p {
  color: #FFF;
  font-size: 26.381px;
}
.blog-detail-page .featureImg::after {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 192px;
  width: 100%;
  content: "";
  background: linear-gradient(181deg, rgba(5, 99, 157, 0) 5.74%, #05639D 85.28%);
}

@media (max-width: 768px) {
  .blog-detail-page .featureImg {
    border-radius: 10px;
    background: lightgray -132px -92.372px/110.811% 153.257% no-repeat;
    height: 240px;
    overflow: hidden;
    position: relative;
  }
  .blog-detail-page .featureImg img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .blog-detail-page .featureImg .keywords {
    position: absolute;
    top: 10px;
    left: 10px;
    border-radius: 4px;
    background: #E9F7FF;
    display: inline-flex;
    padding: 5px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #0082C6;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .blog-detail-page .featureImg .title-box {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: end;
    padding: 15px;
  }
  .blog-detail-page .featureImg .title-box p {
    color: #FFF;
    font-size: 18px;
  }
  .blog-detail-page .featureImg::after {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 192px;
    width: 100%;
    content: "";
    background: linear-gradient(181deg, rgba(5, 99, 157, 0) 5.74%, #05639D 85.28%);
  }
}
.navbar-wrapper.scroll-navbar .navitem {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 1000 !important;
  height: 80px;
  display: flex;
  align-items: center;
}
.navbar-wrapper.scroll-navbar .navitem .navlink {
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
  z-index: 1000 !important;
}
.navbar-wrapper.scroll-navbar .navitem .navlink.active {
  color: #127DC2;
  border-bottom: 2px solid #127DC2;
}
.navbar-wrapper.scroll-navbar .navitem .subnavlist {
  position: absolute;
  width: 220px;
  left: -50px;
  top: 80px;
  display: none;
  z-index: 100;
  border: none;
  border-radius: 10px;
  background: #FFF;
  box-shadow: -3px 4px 10px 2px rgba(0, 0, 0, 0.12);
  padding: 8px !important;
}
.navbar-wrapper.scroll-navbar .navitem .subnavlist .subnavitem {
  border-radius: 4px;
}
.navbar-wrapper.scroll-navbar .navitem .subnavlist .subnavitem .subnavlink {
  border-radius: 4px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
  height: 100%;
  margin-bottom: 8px;
  min-height: 40px;
  transition: 0.1s ease-in-out;
  display: flex;
  align-items: center;
  padding: 0 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  text-decoration: none;
  color: black;
}
.navbar-wrapper.scroll-navbar .navitem .subnavlist .subnavitem:hover, .navbar-wrapper.scroll-navbar .navitem .subnavlist .subnavlink:hover, .navbar-wrapper.scroll-navbar .navitem .subnavlist .subnavlink.active {
  color: #127DC2 !important;
  background: rgba(18, 125, 194, 0.15) !important;
}

.navlist {
  z-index: 1000 !important;
}
.navlist .navitem {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 1000 !important;
  height: 80px;
  display: flex;
  align-items: center;
}
.navlist .navitem .navlink {
  text-decoration: none;
  color: white;
  display: flex;
  align-items: center;
  z-index: 1000 !important;
}
.navlist .navitem .navlink.active {
  border-bottom: 2px solid white;
}
.navlist .navitem .subnavlist {
  position: absolute;
  width: 220px;
  left: -50px;
  top: 80px;
  display: none;
  z-index: 100;
  border: none;
  border-radius: 10px;
  background: #FFF;
  box-shadow: -3px 4px 10px 2px rgba(0, 0, 0, 0.12);
  padding: 8px !important;
}
.navlist .navitem .subnavlist .subnavitem {
  border-radius: 4px;
}
.navlist .navitem .subnavlist .subnavitem .subnavlink {
  border-radius: 4px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
  height: 100%;
  margin-bottom: 8px;
  min-height: 40px;
  transition: 0.1s ease-in-out;
  display: flex;
  align-items: center;
  padding: 0 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  text-decoration: none;
  color: black;
}
.navlist .navitem .subnavlist .subnavitem:hover, .navlist .navitem .subnavlist .subnavlink:hover, .navlist .navitem .subnavlist .subnavlink.active {
  color: #127DC2 !important;
  background: rgba(18, 125, 194, 0.15) !important;
}
.navlist .navitem:hover .subnavlist {
  display: block;
  transform: matrix(0px);
  transform: translateY(0px);
}

.mobile-nav-body .accordion .mobile-link {
  font-size: 20px !important;
}
.mobile-nav-body .accordion .accordion-item {
  border-radius: 0;
  border: none;
}
.mobile-nav-body .accordion .accordion-item .single-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 1rem;
  text-align: left;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  padding: 16px 20px;
  text-decoration: none;
  color: black;
  text-transform: capitalize;
  font-weight: 500;
}
.mobile-nav-body .accordion .accordion-item .accordion-button {
  border-radius: 0;
  box-shadow: none;
  outline: none;
  font-size: 20px;
  font-weight: 500 !important;
  text-transform: capitalize;
  font-weight: 500;
  color: black;
}
.mobile-nav-body .accordion .accordion-item .accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
  transform: scale(0.7) !important;
}
.mobile-nav-body .accordion .accordion-item .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
}
.mobile-nav-body .accordion .accordion-item .accordion-body {
  background-color: #e7ecf3;
  padding: 0;
}
.mobile-nav-body .accordion .accordion-item .accordion-body .link-box {
  height: 52px;
  padding: 16px 20px;
  border-bottom: 1px solid #127DC2;
  font-weight: 500;
}
.mobile-nav-body .accordion .accordion-item .accordion-body .link-box .mobile-link {
  color: #127DC2 !important;
}/*# sourceMappingURL=new.css.map */