.homeSliderWrapper{

    .swiper-button-prev::after,.swiper-button-next::after{
        color: white;
    }
    .swiper-pagination{
        top: 90%;
            .swiper-pagination-bullet{
            color: white !important;
            background-color: white !important;
            height: 10px;
            width: 10px;
        }


        .swiper-pagination-bullet.swiper-pagination-bullet-active{
            width: 25px;
            border-radius: 4px;
        }

      
    }
}

@media (max-width:768px) {
    .slider-bgwave{
        background-size: cover !important;
        height: 650px !important;
    }
    .homeSliderWrapper{
        padding-top: 60px;
        .slider-image{
            margin: auto;
            height: 200px;
          
        }

        .swiper-button-prev::after,.swiper-button-next::after{
            color: white;
        }
        .swiper-pagination{
            top: 90%;
                .swiper-pagination-bullet{
                color: white !important;
                background-color: white !important;
                height: 10px;
                width: 10px;
            }
            .swiper-pagination-bullet.swiper-pagination-bullet-active{
                width: 25px;
                border-radius: 4px;
            }
        }
    }
}

@media (max-width:400px) {
    .slider-bgwave{
        height: 900px !important;
    }
}


.customSliderContainer{
    .swiper-button-prev,.swiper-button-next{
        background-color: #02559F !important;
        height: 30px;
        width: 30px;
        border-radius: 50%;

    }

    .swiper-button-prev{
    
        left: 0% !important;

    }
    .swiper-button-next{
     
        left: 94% !important;
    }
    .swiper-button-prev::after,.swiper-button-next::after{
        color: white;
        font-size: 16px;
    }
    .swiper-pagination{
        top: 90%;
            .swiper-pagination-bullet{
            color: white !important;
            background-color: white !important;
            height: 10px;
            width: 10px;
        }


        .swiper-pagination-bullet.swiper-pagination-bullet-active{
            width: 25px;
            border-radius: 4px;
        }

      
    }
}

.mainContentBox{
    position: relative;
    overflow: hidden;
    padding-top: 80px;

    .pageBgImage{
        position: absolute;
        top: -80px;
        left: -180px;
        right: 0;
        height: 600px;
        z-index: -1;
    }
}

@media (min-width:1600px) {
    .mainContentBox{
        background-size: 100% 40vh !important;
    }
}

@media (min-width:1400px) {
    .mainContentBox{
        background-size: 100% 500px !important;
    }
}

@media (max-width:1400px) {
    .mainContentBox{
        background-size: 100% 500px !important;
    }
}

@media (max-width:768px) {
    .mainContentBox{
        .pageBgImage{
            object-fit: cover !important; 
        }
    }
}





/* homeSwiperSlider */

@media (max-width:1920px) {
    .slider-bgwave{
        height: 500px;
    }
}
@media (max-width:1600px) {
    .slider-bgwave{
        height: 800px;
    }
}

.slider-wrapper-main{
    background-image: url('../Assets/new/sliderbg.png');
    background-size: 100% 100%;

    height: 650px;

}
.homeSwiperSlider{
    

    .content-col{
 
        .title-tag{
            font-size: 35px;
            font-weight: 700;
            color: white;
        }
    
    .white-btn{
        background-color: white  ;
        color: #02559F;
        width: 140px;
        height: 42px;
        border-radius: 5px;
        transition: 0.3s ease-in-out;
        font-size: 18px;
font-style: normal;
font-weight: 400;
    }
    .outline-btn{
        background-color: transparent  ;
        color: white;
        width: 140px;
        height: 42px;
        border-radius: 5px;
        border: 2px solid #FFF;
        transition: 0.3s ease-in-out;
        font-size: 18px;
font-style: normal;
font-weight: 400;
    }
    .outline-btn:hover{
        background-color: white  ;
        color: #02559F;
        border-radius: 5px;
    }
    }
    .img-col{
        img{
            user-select: none;
        }
    }

   .mySwiper{
   height: 500px;
 
    .swiper-pagination.swiper-pagination-fraction.swiper-pagination-horizontal{
        color: white;
        position: absolute;
        bottom: 0px;
        left: 0;
        height: 40px;
        width: 100px;
        font-size: 25px;
      
    }
    .swiper-button-next,.swiper-button-prev{
        position: absolute;
        top: 99% !important;
        height: fit-content;
    }
    .swiper-button-next::after,.swiper-button-prev::after{
        color: white;
        font-size: 20px;
    }
    .swiper-button-prev{
        left: 100px;
    }

    .swiper-button-next{
        left: 130px; 
    }

    .homelsiderimg{
 
        height: 100%;
        width: 100%;
        object-fit: contain;
    }

}
}
@media (max-width:980px) {
    .slider-wrapper-main{
        background-color: #0082C6;
        background-image: none;
    
        height: 650px;
    
    }
    .homeSwiperSlider{
     
 
        .content-col{

            .title-tag{
                font-size: 20px;
            }
        
        .white-btn{
            background-color: white  ;
            color: #02559F;
            width: 120px;
            height: 35px;
            border-radius: 5px;
            transition: 0.3s ease-in-out;
            font-size: 16px;
    font-style: normal;
    font-weight: 400;
        }
        .outline-btn{
            background-color: transparent  ;
            color: white;
            width: 120px;
            height: 35px;
            border-radius: 5px;
            border: 2px solid #FFF;
            transition: 0.3s ease-in-out;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
        }
        .outline-btn:hover{
            background-color: white  ;
            color: #02559F;
            border-radius: 5px;
        }
        }
        .img-col{
            img{
                height: 220px !important;
                object-fit: contain;
                
            }
        }
        .mySwiper{
            height: 600px;
        }
    
  
    }
}

.cardsServices{
    width: 100% !important;
    .img-box{
        overflow: hidden;
        border-radius: 16px;
        img{
            height: 100%;
            width: 100%;
            object-fit: fill;
            transition: 0.3s ease-in-out;
        }
    }
}

.cardsServices:hover{
    width: 100% !important;
    .img-box{
        img{
            height: 100%;
            width: 100%;
            object-fit: fill;
            transform: scale(1.2);
        }
    }
}


.blog-detail-page{
    .featureImg{
        border-radius: 20px;
        background:  lightgray -132px -92.372px / 110.811% 153.257% no-repeat;
        height: 484px;
        overflow: hidden;
        position: relative;
        img{
            height: 100%;
            width: 100%;
        }
        .keywords{
            position: absolute;
            top: 20px;
            left: 20px;
            border-radius: 8px;
            background: #E9F7FF;
            display: inline-flex;
            padding: 10px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            color: #0082C6;

            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .title-box{
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 10;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: end;
            padding: 20px 40px;
      
            p{
                color: #FFF;
                font-size: 26.381px;
            }
        }
    }
    .featureImg::after{
        position: absolute;
        bottom: 0;
        left: 0;
        height: 192px;
        width: 100%;
        content: "";
        background: linear-gradient(181deg, rgba(5, 99, 157, 0.00) 5.74%, #05639D 85.28%);
    }
}

@media (max-width:768px) {
    .blog-detail-page{
        .featureImg{
            border-radius: 10px;
            background:  lightgray -132px -92.372px / 110.811% 153.257% no-repeat;
            height: 240px;
            overflow: hidden;
            position: relative;
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
            .keywords{
                position: absolute;
                top:10px;
                left:10px;
                border-radius: 4px;
                background: #E9F7FF;
                display: inline-flex;
                padding: 5px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                color: #0082C6;
    
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
    
            .title-box{
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 10;
                height: 100%;
                width: 100%;
                display: flex;
                align-items: end;
                padding: 15px;
          
                p{
                    color: #FFF;
                    font-size: 18px;
                }
            }
        }
        .featureImg::after{
            position: absolute;
            bottom: 0;
            left: 0;
            height: 192px;
            width: 100%;
            content: "";
            background: linear-gradient(181deg, rgba(5, 99, 157, 0.00) 5.74%, #05639D 85.28%);
        }
    }
}












.navbar-wrapper.scroll-navbar{

    .navitem{
        position: relative;
        width: fit-content;
        z-index: 1000 !important;
        height: 80px;
        display: flex;
        align-items: center;
        .navlink{
        text-decoration: none;
        color: black;
        // padding: 10px 15px;
        display: flex;
        align-items: center;
        z-index: 1000 !important;
       }
      

       .navlink.active{
        color: #127DC2;
        border-bottom: 2px solid #127DC2;
        }

       .subnavlist{
        position: absolute;
        width: 220px;
        left: -50px;
        top: 80px;
        display: none;
        z-index: 100;
        border: none;
        border-radius: 10px;
        background: #FFF;
        box-shadow: -3px 4px 10px 2px rgba(0, 0, 0, 0.12);
        padding: 8px !important;

        .subnavitem{
            border-radius: 4px;

            .subnavlink{
                border-radius: 4px;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                width: 100%;
                height: 100%;
                margin-bottom: 8px;
                min-height: 40px;
                
                transition: 0.1s ease-in-out;
                display: flex;
                align-items: center;
                padding: 0 10px ;
                display: flex;
                align-items: center;
                flex-direction: row;
                text-decoration: none;
                color: black;
            }
        }

        .subnavitem:hover,.subnavlink:hover,.subnavlink.active{
            color: #127DC2 !important;
            background: rgba(18, 125, 194, 0.15) !important;
        }

       }

    }
}
.navlist{
    z-index: 1000 !important;
    
    .navitem{
        position: relative;
        width: fit-content;
        z-index: 1000 !important;
        height: 80px;
        display: flex;
        align-items: center;
        .navlink{
        text-decoration: none;
        color: white;
        // padding: 10px 15px;
        display: flex;
        align-items: center;
        z-index: 1000 !important;
       }
      

       .navlink.active{
        border-bottom: 2px solid white;
        }

       .subnavlist{
  
        position: absolute;
        width: 220px;
        left: -50px;
        top: 80px;
        display: none;
        z-index: 100;
        border: none;
        border-radius: 10px;
        background: #FFF;
        box-shadow: -3px 4px 10px 2px rgba(0, 0, 0, 0.12);
        padding: 8px !important;

        .subnavitem{
            border-radius: 4px;

            .subnavlink{
                border-radius: 4px;
               
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                width: 100%;
                height: 100%;
                margin-bottom: 8px;
                min-height: 40px;
                
                transition: 0.1s ease-in-out;
                display: flex;
                align-items: center;
                padding: 0 10px ;
                display: flex;
                align-items: center;
                flex-direction: row;
                text-decoration: none;
                color: black;
            }
        }

        .subnavitem:hover,.subnavlink:hover,.subnavlink.active{
            color: #127DC2 !important;
            background: rgba(18, 125, 194, 0.15) !important;
        }

       }

    }
    // .navitem:last-child{
    //     position: relative;
    //     width: fit-content;
    //    .navlink.active{
    //     background-color: white;
    //     border-radius: 6px;
    //     box-shadow: rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
    //    }
    //    .subnavlist{
    //     right: 0;
    //    }

    // }

    .navitem:hover{
            .subnavlist{
                display: block;
                transform: matrix(0px);
                transform: translateY(0px);
            }
    }





}


.navbar-wrapper{



    
//     .accordion{
//     .accordion-item{
//         border-radius: 0;
//         border : none;
//         .single-button{
//             position: relative;
//             display: flex;
//             align-items: center;
//             width: 100%;
//             font-size: 1rem;
//             text-align: left;
//             border: 0;
//             border-radius: 0;
//             overflow-anchor: none;
//             padding: 16px 20px;
//             text-decoration: none;
//             color: black;
        
//         }
//         .accordion-button{
//             border-radius: 0;
//             box-shadow: none;
//             outline: none;
//             font-size: 16px;
//             font-weight: 500 !important;
//         }

//         .accordion-button::after {
//             background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
//             transform: scale(.7) !important;
//           }
//           .accordion-button:not(.collapsed)::after {
//             background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
//           }
//           .accordion-body{
//             background-color: #e7ecf3;
//             padding: 0;
//             ul{
//                 li{
           
//                     height: 52px;
//                     padding: 16px 20px;
//                     border-bottom: 1px solid #02559F;

//                 }
//             }
//           }
//     }
// }

}

.mobile-nav-body{
    .accordion{
        .mobile-link{
            font-size: 20px !important;
        }
        .accordion-item{
            border-radius: 0;
            border : none;
            .single-button{
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                font-size: 1rem;
                text-align: left;
                border: 0;
                border-radius: 0;
                overflow-anchor: none;
                padding: 16px 20px;
                text-decoration: none;
                color: black;
                text-transform: capitalize;
                font-weight: 500;
            
            }
            .accordion-button{
                border-radius: 0;
                box-shadow: none;
                outline: none;
                font-size: 20px;
                font-weight: 500 !important;
                text-transform: capitalize;
                font-weight: 500;
                color: black ;
            }

            .accordion-button::after {
                background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
                transform: scale(.7) !important;
              }
              .accordion-button:not(.collapsed)::after {
                background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
              }
              .accordion-body{
                background-color: #e7ecf3;
                padding: 0;
        
                    .link-box{
                        height: 52px;
                        padding: 16px 20px;
                        border-bottom: 1px solid #127DC2;
                        font-weight: 500;
                        .mobile-link{
                        color: #127DC2 !important;
                        }

                    }
                }
      
        }
    }
}