.btn:focus {
  outline: none;
  box-shadow: none;
}

.ocean {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
}
.wave {
  /* background: url(/public/Vector.svg) repeat-x; */
  position: absolute;
  top: -198px;
  width: 6400px;
  height: 198px;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}
.wave:nth-of-type(2) {
  top: -175px;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
    swell 7s ease -1.25s infinite;
  opacity: 1;
}
@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}
@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}


.squarespin {
  animation: squarespinmotion 50s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite;
}

@keyframes squarespinmotion {
  0%, 100% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.tianglespin {
  animation: tianglespinmotion 50s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite;
}

@keyframes tianglespinmotion {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(50px, 50px);
  }
  50% {
    transform: translate(-50px, 50px);
  }
  75% {
    transform: translate(50px, -50px);
  }
  100% {
    transform: translate(-50px, -50px);
  }
}



