ul {
  padding: 0px !important;
  margin: 0px !important;
}
li {
  list-style: none;
}
/* p {
  margin: 0px !important;
} */
footer {
  color: #fff;
  position: relative;
  overflow: hidden;
}

footer strong {
  color: #fff;
}
footer .footer-logo {
  margin-bottom: 25px;
  width: 208px;
}

footer .footer-bottom {
  background-color: #004c74;
  font-size: 15px;
  padding: 15px 0;
  z-index: 999;
}

.footer-info {
  border-bottom: 1px solid #e1e1f0;
  margin-bottom: 20px;
  padding-bottom: 10px;
}
.footer-info .footer-logo {
  margin-bottom: 10px;
}

@media only screen and (max-width: 991px) {
  .footer-info .footer-info .footer-logo {
    margin-bottom: 20px;
  }
}
